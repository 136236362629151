import axios from "axios";

import { getApiUrl } from "../utils/config";
import api from "./api";

export const fetchPickupCountries = query =>
  api.get({
    path: "/v1/reference/pickupcountries",
    query,
  });

export const fetchProfiles = userId =>
  api.get({
    path: `/v1/user/${userId}/profile`,
  });

export const createReturn = body =>
  api.post({ path: "/v1/return/shipment", body });

export const getLabel = (shipmentId, query) =>
  api.get({
    path: `/v1/return/shipment/${shipmentId}/label`,
    query,
    headers: { accept: "text/html" },
  });
export const getBarcode = (shipmentId, query = {}) =>
  api.get({
    path: `/v1/return/shipment/${shipmentId}/barcode`,
    query: {
      type: "dropoff",
      sendEmail: true,
      ...query,
    },
    headers: { accept: "text/html" },
  });

export const getLabelAxios = async (shipmentId, sendEmail) => {
  const domain = getApiUrl();

  const { data } = await axios.get(
    `${domain}/v1/return/shipment/${shipmentId}/label?sendEmail=${sendEmail}`,
    {
      headers: { accept: "text/html" },
      withCredentials: true,
    }
  );
  return data;
};
