import { PARCEL_STATUS, utils } from "@dpdgroupuk/redback-enums";

import {
  CivilUnrest,
  Covid,
  Fire,
  FlightDelay,
  Flood,
  LocalEvent,
  NoSailing,
  Olympic,
  Snowflake,
  Traffic,
  Unexpected,
  Warning,
  Winds,
} from "../components/Icon";

// REDUX FORMS
export const RETURN_FORM = "return";
export const EMAIL_TYPE_FORM = "EMAIL_TYPE_FORM";
export const ACCOUNT_SEARCH_FORM = "search";
export const FILTER_FORM = "filter";
export const DASHBOARD_FORM = "dashboard";

// CREATE RETURN FORM
export const CREATE_RETURN_FIELDS = [
  "returnFromShortName",
  "returnFromCountry",
  "returnFromPostcode",
  "returnFromOrganisation",
  "returnFromAddress1",
  "returnFromAddress2",
  "returnFromCity",
  "returnFromCounty",
  "returnFromName",
  "returnFromTelephone",
  "returnFromEmail",
  "returnFromNumberOfParcels",
  "returnFromTotalWeight",
  "returnReference",
  "returnToShortName",
  "returnToPostcode",
  "returnToOrganisation",
  "returnToAddress1",
  "returnToAddress2",
  "returnToCity",
  "returnToCounty",
  "returnToContactName",
  "emailType",
];

export const RETURN_FROM_REVIEW_DETAILS = [
  { inputName: "returnFromOrganisation" },
  { inputName: "returnFromAddress1" },
  { inputName: "returnFromAddress2" },
  { inputName: "returnFromCity" },
  { inputName: "returnFromPostcode" },
  { inputName: "returnFromCounty" },
  { inputName: "returnReference" },
];

export const RETURN_FROM_REVIEW_CONTACT_DETAILS = [
  { inputName: "returnFromName", title: "Contact" },
  { inputName: "returnFromTelephone", title: "Telephone" },
  { inputName: "returnFromEmail", title: "Email" },
  { inputName: "returnFromNumberOfParcels", title: "No Of Parcels" },
  { inputName: "returnFromTotalWeight", title: "Total Weight" },
];

export const RETURN_TO_REVIEW_DETAILS = [
  { inputName: "returnToOrganisation" },
  { inputName: "returnToAddress1" },
  { inputName: "returnToAddress2" },
  { inputName: "returnToCity" },
  { inputName: "returnToPostcode" },
  { inputName: "returnToCounty" },
  { inputName: "returnToContactName" },
];

export const REQUIRED_ADDRESS_BOOK_FIELDS = [
  "Country",
  "ShortName",
  "Address1",
  "City",
  "Postcode",
];

// ACCOUNT SEARCH FORM
export const SEARCH_BY_FIELDS = [
  "searchByValue",
  "searchByFromDate",
  "searchByToDate",
  "searchByPostcode",
];

export const ACCOUNT_SEARCH_TYPES = {
  PARCEL_NUMBER: "Parcel Number",
  RETURNS_REFERENCE: "Returns Reference",
  DATE_SEARCH: "Date Search",
  FROM_DATE: "From Date",
  TO_DATE: "To Date",
  POSTCODE: "Postcode",
};

export const SEARCH_BY_DROPDOWN = [
  {
    value: ACCOUNT_SEARCH_TYPES.PARCEL_NUMBER,
    label: ACCOUNT_SEARCH_TYPES.PARCEL_NUMBER,
  },
  {
    value: ACCOUNT_SEARCH_TYPES.RETURNS_REFERENCE,
    label: ACCOUNT_SEARCH_TYPES.RETURNS_REFERENCE,
  },
  {
    value: ACCOUNT_SEARCH_TYPES.DATE_SEARCH,
    label: ACCOUNT_SEARCH_TYPES.DATE_SEARCH,
  },
];

export const joiMessages = {
  any: {
    required: "{{label}} must be entered",
  },
  string: {
    length: "{{label}} value must be {{limit}} digits",
    max: "{{label}} must be a maximum of {{limit}} characters",
    min: "{{label}} must be more than {{limit}} characters",
    empty: "{{label}} must be entered",
    regex: {
      name: "A valid {{label}} must be entered",
    },
  },
  number: {
    base: "{{label}} must be a number",
    min: "{{label}} must be more than {{limit}}",
    max: "{{label}} must be a maximum of {{limit}}",
    greater: "{{label}} must be equal or greater than {{limit}}",
    less: "{{label}} must be equal or less than {{limit}}",
  },
};

// FILTER SEARCH FORM
export const FILTER_FORM_FIELDS = {
  ACCOUNT_CODE: "accountCode",
  COLLECT_COUNTRY: "collectionCountryCode",
  SORT_BY: "sortBy",
  PRODUCT: "productCode",
  SERVICE: "serviceCode",
  DELIVERY_DEPOT: "deliveryDepot",
  OFD_TODAY: "ofdToday",
  DELIVERY_COUNTRY: "deliveryCountryCode",
  POSTCODE: "postcode",
  DESTINATION: "destinationTypeCode",
  RANGE: "range",
  DELIVERED: "undelivered",
  DIRECTION: "directionCode",
  OVERDUE: "searchDays",
  EXCEPTIONS: "stateReasonCode",
};

export const FILTER_FIELD_DEFAULT_VALUES = {
  [FILTER_FORM_FIELDS.PRODUCT]: "all",
  [FILTER_FORM_FIELDS.DELIVERY_COUNTRY]: "all",
  [FILTER_FORM_FIELDS.ACCOUNT_CODE]: "all",
  [FILTER_FORM_FIELDS.SERVICE]: "all",
  [FILTER_FORM_FIELDS.POSTCODE]: "",
  [FILTER_FORM_FIELDS.COLLECT_COUNTRY]: "all",
  [FILTER_FORM_FIELDS.DELIVERY_DEPOT]: "all",
  [FILTER_FORM_FIELDS.DESTINATION]: "all",
  [FILTER_FORM_FIELDS.RANGE]: "all",
  [FILTER_FORM_FIELDS.OFD_TODAY]: "na",
  [FILTER_FORM_FIELDS.DELIVERED]: "na",
  [FILTER_FORM_FIELDS.SORT_BY]: "na",
  [FILTER_FORM_FIELDS.DIRECTION]: "all",
};

export const SORT_BY_FILTER = [
  utils.createEnum("not-specified", "Not Specified"),
  utils.createEnum("CUSTOMER.CODE-A", "Account No (ascending)"),
  utils.createEnum("CUSTOMER.CODE-D", "Account No (descending)"),
  utils.createEnum("I_PARCEL_NO-A", "Parcel No (ascending)"),
  utils.createEnum("I_PARCEL_NO-D", "Parcel No (descending)"),
  utils.createEnum("I_CON_NO-A", "Consignment No (ascending) "),
  utils.createEnum("I_CON_NO-D", "Consignment No (descending)"),
  utils.createEnum("COLLECT.DATE-A", "Collection Date (ascending)"),
  utils.createEnum("COLLECT.DATE-D", "Collection Date (descending)"),
  utils.createEnum("DEL.DEPOT-A", "Delivery Depot (ascending)"),
  utils.createEnum("DEL.DEPOT-D", "Delivery Depot (descending)"),
];

export const DESTINATION_FILTER_DROPDOWN_OPTIONS = [
  {
    value: "all",
    label: "All Destinations",
  },
  {
    value: "D",
    label: "DOMESTIC",
  },
  {
    value: "I",
    label: "International",
  },
];

export const OFD_TODAY_FILTER_DROPDOWN_OPTIONS = [
  {
    value: "na",
    label: "-",
  },
  {
    value: "Y",
    label: "Yes",
  },
  {
    value: "N",
    label: "No",
  },
];

export const DELIVERED_FILTER_DROPDOWN_OPTIONS = [
  {
    value: "na",
    label: "-",
  },
  {
    value: "N",
    label: "Yes",
  },
  {
    value: "Y",
    label: "No",
  },
];

export const DIRECTION_FILTER_DROPDOWN_OPTIONS = [
  {
    value: "all",
    label: "All Directions",
  },
  {
    value: "O",
    label: "Outbound",
  },
  {
    value: "I",
    label: "Inbound",
  },
];

export const COLLECTION_COUNTRIES_DROPDOWN_OPTIONS = [
  {
    value: "all",
    label: "All Countries",
  },
  {
    value: "AT",
    label: "Austria",
  },
  {
    value: "BE",
    label: "Belgium",
  },
  {
    value: "CZ",
    label: "Czech Republic",
  },
  {
    value: "DK",
    label: "Denmark",
  },
  {
    value: "EE",
    label: "Estonia",
  },
  {
    value: "FI",
    label: "Finland",
  },
  {
    value: "FR",
    label: "France",
  },
  {
    value: "DE",
    label: "Germany",
  },
  {
    value: "LV",
    label: "Latvia",
  },
  {
    value: "LT",
    label: "Lithuania",
  },
  {
    value: "LU",
    label: "Luxembourg",
  },
  {
    value: "NL",
    label: "Netherlands",
  },
  {
    value: "PL",
    label: "Poland",
  },
  {
    value: "PT",
    label: "Portugal",
  },
  {
    value: "SK",
    label: "Slovakia",
  },
  {
    value: "ES",
    label: "Spain",
  },
  {
    value: "SW",
    label: "Sweden",
  },
  {
    value: "CH",
    label: "Switzerland",
  },
  {
    value: "UK",
    label: "United Kingdom",
  },
].sort((a, b) => {
  if (a.label < b.label) return -1;
  if (a.label > b.label) return 1;
  return 0;
});

export const issueIcons = [
  Unexpected,
  Fire,
  Flood,
  Snowflake,
  CivilUnrest,
  NoSailing,
  Traffic,
  Winds,
  FlightDelay,
  Olympic,
  LocalEvent,
  Covid,
];

export const statusIcons = {
  [PARCEL_STATUS.RFI_REASON]: Warning,
};

export const CSV_MAX_ROW_COUNT = 5000;
