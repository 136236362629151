import { useCallback, useMemo } from "react";

import { get, last } from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";

import { withLoader } from "@dpdgroupuk/mydpd-ui";

import { Delimiter } from "../../../../../components/Delimiter/Delimiter";
import {
  getMapUrl,
  getParcelProgressData,
} from "../../../../../helpers/ParcelView";
import {
  fetchDriver,
  fetchRoute,
} from "../../../../../redux/parcelView/actions";
import { EtaBar } from "../EtaBar/EtaBar";
import { MapView } from "../MapView/MapView";
import { ParcelDetails } from "../ParcelDetails/ParcelDetails";
import { ReturnStatus } from "../ReturnStatus/ReturnStatus";

const OutForDeliveryParcelView = ({ parcel, driver, route }) => {
  const {
    outForDeliveryDetails: { mapAvailable },
    parcelCode,
    deliveryDetails,
  } = parcel;

  const result = useMemo(
    () => getParcelProgressData(parcel, route, driver),
    [parcel]
  );

  const Map = useCallback(() => {
    return (
      <MapView
        src={
          mapAvailable && deliveryDetails?.address?.udprn
            ? getMapUrl(parcelCode)
            : ""
        }
      />
    );
  }, [mapAvailable, deliveryDetails]);

  return (
    <>
      <ReturnStatus parcel={parcel} />
      <Map />
      <EtaBar {...result} />
      <Delimiter />
      <ParcelDetails data={parcel} />
    </>
  );
};

OutForDeliveryParcelView.propTypes = {
  parcel: PropTypes.object,
  parcelEvents: PropTypes.object,
  driver: PropTypes.object,
  route: PropTypes.object,
};

export default compose(
  connect(state => ({
    route: state.ui.parcelView.route,
    driver: state.ui.parcelView.driver,
  })),
  withLoader({
    loadFn: async ({ dispatch, parcel, parcelEvents }) => {
      const { deliveryDetails = {} } = parcel;
      const parcelEvent = last(parcelEvents);
      const routeTime = get(parcelEvent, "routeTime");
      const routeNumber = get(parcelEvent, "routeNo");
      const depotCode = get(deliveryDetails, "depotCode");
      const routeCode = "0011*19750*156*3600";

      const route = await dispatch(
        fetchRoute({
          ...(routeCode && { routeCode }),
          ...(routeTime && { routeTime }),
          ...(routeNumber && { routeNumber }),
          ...(depotCode && { depotCode }),
        })
      );

      const driverCode = get(route.data, "driverCode");
      await dispatch(fetchDriver(`${depotCode}*${driverCode}`));
    },
  })
)(OutForDeliveryParcelView);
