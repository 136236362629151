import { useCallback, useEffect } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm, reset } from "redux-form";

import { Button, Modal } from "@dpdgroupuk/mydpd-ui";

import RadioField from "../../../../../components/Radio";
import { EMAIL_TYPE_FORM } from "../../../../../constants/forms";
import styles from "./SendMailModal.module.scss";

const SendMailModal = ({ showModal, setShowModal, onSendEmail }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!showModal) {
      dispatch(reset(EMAIL_TYPE_FORM));
    }
  }, [showModal]);

  const onClose = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  return (
    <Modal show={showModal} onHide={onClose}>
      <Modal.Body>
        <Container fluid>
          <Row>
            <Col className={classNames("col", styles.header)}>
              Email Pickup Details
            </Col>
          </Row>
          <Row>
            <Col className={classNames("col-12", styles.text)}>
              Please select the type of details you require:
            </Col>
            <Col className="col-12">
              <Field
                component={RadioField}
                name="emailType"
                value="label"
                type="radio"
                label="Pickup Details With Label"
              />
            </Col>
            <Col className="col-12">
              <Field
                component={RadioField}
                name="emailType"
                value="QR"
                type="radio"
                label="Pickup Details With QR Code"
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" className="button" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="button"
          onClick={() => {
            onSendEmail();
            onClose();
          }}
        >
          Send Email
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

SendMailModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  onSendEmail: PropTypes.func,
};

export default compose(
  reduxForm({
    form: EMAIL_TYPE_FORM,
    initialValues: {
      emailType: "label",
    },
  })
)(SendMailModal);
