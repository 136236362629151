import { useState } from "react";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { lifecycle, withProps } from "recompose";
import { compose } from "redux";
import { reduxForm } from "redux-form";

import { AuthUtil, withAppUserPreferences } from "@dpdgroupuk/mydpd-app";
import {
  Legend,
  Main,
  Step,
  withLoader,
  withOverlay,
} from "@dpdgroupuk/mydpd-ui";

import { addFuncIdToLog } from "../../../apis/functionalityLog";
import { AccountDropdown } from "../../../components/AccountDropdown/AccountDropdown";
import { DASHBOARD_FORM } from "../../../constants/forms";
import { AuthSelectors, DashboardActions } from "../../../redux";
import { calculateExpectedReturnDates } from "../../../utils/date";
import { formatDashboardStats } from "../../../utils/format";
import { DashboardStats } from "./components/DashboardStats/DashboardStats";

const Dashboard = ({
  authUser,
  fetchReturnsDashboard,
  accounts,
  businessUnit,
  overlay,
}) => {
  const { stats } = useSelector(state => state.ui.dashboard);
  const [selectedAccount, setSelectedAccount] = useState(accounts);

  const handleOnAccountChange = async account => {
    overlay.show();
    const expectedReturnDates = calculateExpectedReturnDates();
    const accountNumber = account === "All Accounts" ? accounts : account;

    const fetchReturnsDashboardRes = {
      accountNumber,
      businessUnit,
      expectedDate: expectedReturnDates,
    };

    await fetchReturnsDashboard(fetchReturnsDashboardRes);
    setSelectedAccount(accountNumber);
    overlay.hide();
  };

  return (
    <Main.Body>
      <Legend
        leftMessage="Shop Returns Dashboard"
        rightMessage={`${AuthUtil.getDisplayName(authUser?.user)} (Account: ${
          authUser?.user?.account
        })`}
      />
      <form>
        <Col className="mb-3" md={12}>
          <Step
            title="Shop Returns Dashboard"
            helpModalTitle={"Shop Returns Dashboard"}
          >
            <AccountDropdown
              accounts={["All Accounts", ...accounts]}
              onChange={e => handleOnAccountChange(e.target.value)}
            />
          </Step>
        </Col>
        {!isEmpty(stats) && (
          <Col md={12}>
            <Step title="For Your Attention">
              <DashboardStats
                stats={formatDashboardStats(stats)}
                account={selectedAccount}
              />
            </Step>
          </Col>
        )}
      </form>
    </Main.Body>
  );
};

Dashboard.propTypes = {
  authUser: PropTypes.object,
  fetchReturnsDashboard: PropTypes.func,
  accounts: PropTypes.array,
  businessUnit: PropTypes.string,
  overlay: PropTypes.object,
};

export default compose(
  withAppUserPreferences,
  withOverlay,
  connect(
    state => ({
      accounts: AuthSelectors.getAccounts(state),
      businessUnit: AuthSelectors.getBusinessUnit(state),
    }),
    dispatch => ({
      fetchReturnsDashboard: params =>
        dispatch(DashboardActions.fetchReturnsDashboard(params)),
    })
  ),
  withLoader({
    loadFn: async ({ fetchReturnsDashboard, accounts, businessUnit }) => {
      await fetchReturnsDashboard({
        accountNumber: accounts,
        businessUnit,
        expectedDate: calculateExpectedReturnDates(),
      });

      await Promise.all([
        addFuncIdToLog("de3ce613c39744e2a9ac8fe20b5384b1"),
        addFuncIdToLog("2329592d0b7b4bb6a2cb4dad0254b70f"),
        addFuncIdToLog("483940dc8a014da7a55011dadfae6f9b"),
      ]);
    },
  }),
  withProps(({ overlay }) => ({
    overlay,
  })),
  reduxForm({
    form: DASHBOARD_FORM,
    initialValues: {
      account: "All Accounts",
    },
  }),
  lifecycle({
    componentWillUnmount() {
      this.props.dispatch(DashboardActions.resetDashboard());
    },
  })
)(Dashboard);
